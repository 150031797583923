import './App.scss';
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
/* import Nav from "./Components/Nav"; */
import Home from "./Components/Home";
import CristinaNeves from "./Components/CristinaNeves";

function App() {
  const location = useLocation();

  return (
    <div className="site">
      {/* <Nav /> */}
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route index element={<Home />} />
          <Route path="/cristina-neves" element={<CristinaNeves />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
