import React from "react";
import transition from "../transition";
import office from '../images/office.png';
import whatsappButton from '../images/whatsapp-button.svg';
import crisLogo from '../images/cristina-logo.svg';

const CristinaNeves = () => {
  return (
    <div className="site-page cristina-neves">
      <div className="nav">
        <div className="nav-logo">
          <img src={crisLogo} alt="Cristina Neves" />
          <span>Cristina Neves Psicologia</span>
        </div>
        <div className="nav-items">
          <div className="nav-item">
            <button className="whatsapp-button">
              <img src={whatsappButton} alt="Whatsapp" />
              whatsapp
            </button>
          </div>
        </div>
      </div>      

      <div className="section">
        <div className="banner">
          <img src={office} className="banner-image" alt="" />

          <div className="banner-text">
            <div className="banner-title">
              Evolução pessoal na prática
            </div>
            <div className="banner-subtitle">
              Ajudamos você a ser mais confiante e conquistar independência emocional
            </div>
          </div>
        </div>
      </div>

      <div className="divider"></div>

      <div className="section">
        <div className="section-focus">

          <div className="section-item">
            <div className="card">
              <div className="card-section">
                <div className="card-icon" />
              </div>
              <div className="card-section">
                <div className="card-title">
                  Benefícios de quem faz terapia
                </div>
              </div>
              <div className="card-section">
                <p className="card-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                    Nullam euismod, urna id aliquam iaculis.
                  </p>
              </div>
            </div>
          </div>

          <div className="section-item">
            <div className="card">
              <div className="card-section">
                <div className="card-icon" />
              </div>
              <div className="card-section">
                <div className="card-title">
                  Benefícios de quem faz terapia
                </div>
              </div>
              <div className="card-section">
                <p className="card-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                    Nullam euismod, urna id aliquam iaculis.
                  </p>
              </div>
            </div>
          </div>

          <div className="section-item">
            <div className="card">
              <div className="card-section">
                <div className="card-icon" />
              </div>
              <div className="card-section">
                <div className="card-title">
                  Benefícios de quem faz terapia
                </div>
              </div>
              <div className="card-section">
                <p className="card-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                    Nullam euismod, urna id aliquam iaculis.
                  </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default transition(CristinaNeves);