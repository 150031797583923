import React from "react";
import transition from "../transition";
import logo from '../images/logo.svg';

const Home = () => {
  return (
    <div className="site-page julio-inglez">
      <img src={logo} className="logo" alt="logo" />
      <div className="logo-animation">
        <svg className="logo-stroke" alt="logo" viewBox="0 0 1000 1000">
          <path d="m7.26,125.99l860.58-.51,1.74,379.48c0,203.78-165.79,369.56-369.56,369.56h-.02c-203.78,0-369.56-168.41-369.56-372.19"/>
        </svg>
      </div>      
    </div>
  );
};

export default transition(Home);